import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";
import { handleTakeNextQuestion } from "../../../utils/helpers";
import "../../../assets/styles/index.css";
import { BACKEND_URL } from "../../../services/QuizApi";
import ContentEditable from "react-contenteditable";

export default function EssayQuestion() {
  const [isFirst, setIsFirst] = useState(true);
  const [currentWordCount, setCurrentWordCount] = useState(0);
  const dispatch = useDispatch();
  const { setQuizProgress, setCompleteQuestion } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const quizGeneralSettings = useSelector((state) => state.quizGeneralSettings);

  const quizProgress = useSelector((state) => state.quizProgress);
  const completeQuestion = useSelector((state) => state.completeQuestion);
  const isTimeOutForceSubmit = useSelector(
    (state) => state.isTimeOutForceSubmit
  );
  let submitButtonRef = useRef(null);

  const convertHtmlToText = (text) => {
    const ele = document.createElement("div");
    ele.innerHTML = text;
    return ele.innerText;
  };

  const takeQuizQuestion = useSelector((state) => state.takeQuizQuestion);
  const quizBrowserSettings = useSelector((state) => state.quizBrowserSettings);
  const quizResult = useSelector((state) => state.quizResult);
  const isWordLimitExceed = useRef(false);
  const wordCountRef = useRef(null);

  const getData = ()=>{
    let res = JSON.parse(sessionStorage.getItem('essay-ans'));
    let condition = parseInt(res?.key.split('-')[0]) === takeQuizQuestion?.question_id && parseInt(res?.key.split('-')[1]) === quizResult?.id;
    if(condition){
      return res?.value;
    }else{
      return '';
    }
  }
  const [answerText, setAnswerText] = useState(getData());


  const handleSubmit = (event) => {
    handleTakeNextQuestion(takeQuizQuestion["question_id"], {
      text: answerText,
    });
    event.preventDefault();
  };

    const handleTextChange = (e) => {
    const words = convertHtmlToText(e.target.value.replaceAll('</div>','</div>\n')).trim().split(/\s+/);
    if(parseInt(words.length) <= parseInt(takeQuizQuestion?.options?.max_word)){
      sessionStorage.setItem('essay-ans',JSON.stringify({key:`${takeQuizQuestion?.question_id}-${quizResult?.id}`,value:e.target.value}));
      setAnswerText(e.target.value);
      setCurrentWordCount(words.length);
      isWordLimitExceed.current = false;
    }else{
      isWordLimitExceed.current = true;
    }
  };

  useEffect(() => {
    if (isTimeOutForceSubmit) {
      submitButtonRef?.current?.click();
    }
  }, [isTimeOutForceSubmit]);

  const handlePaste = (e) =>{
    e.preventDefault();
    if(quizBrowserSettings?.disablepasteinessay){
      return false;
    }
    if (isWordLimitExceed.current) {
      return false;
    }
    let text = (e.clipboardData || window.clipboardData).getData('text');
    let words = text.split(/\s+/);
    let essayWordCount = parseInt(wordCountRef.current.innerHTML);
    if(words.length > parseInt(takeQuizQuestion?.options?.max_word) - parseInt(essayWordCount) ){
      text = words.slice(0,(parseInt(takeQuizQuestion?.options?.max_word) - parseInt(essayWordCount))).join(' ');
      isWordLimitExceed.current = true;
    }
    if(!quizBrowserSettings?.highlightcopypaste){
      document.execCommand('insertText', false, text);
    }else{
      document.execCommand('insertHTML', false, `<span style="background-color:yellow">${text}</span>`);
    } 
  }

  const handleEssayKeyDown = (e) => {
    if (isFirst) {
      setQuizProgress(
        (completeQuestion / quizGeneralSettings.selectnumberofquestion) * 100
      );
      sessionStorage.setItem("quizProgress", (completeQuestion / quizGeneralSettings.selectnumberofquestion) * 100);
      setIsFirst(false);
    }
    if (e.key === ' ') {
      let essayWordCount = parseInt(wordCountRef.current.innerHTML);
      if (essayWordCount >= takeQuizQuestion?.options?.max_word) {
        e.preventDefault();
        return false;
      }
      return true;
    }
    if (e.key.length === 1 && !(e.ctrlKey || e.metaKey || e.altKey || e.shiftKey)) {
      e.preventDefault();
      if (isWordLimitExceed.current) {
        return false;
      }
      if(!quizBrowserSettings?.highlightcopypaste){
        document.execCommand('insertText', false, e.key);
      }else{
        document.execCommand('insertHTML', false, `<span style="background-color:white;">${e.key}</span>`);
      }
    } else {
      if(e.key === "Enter"){
        let essayWordCount = parseInt(wordCountRef.current.innerHTML);
        if (essayWordCount >= takeQuizQuestion?.options?.max_word) {
          e.preventDefault();
          return false;
        }
        document.execCommand('insertHTML', false, '\n');
      }
      return true;
    }
  }

  return (
    <div className="quizcontainer">
      <div className="quizcontainer">
        <div className="take-common-questions">
          <div className="question-title">
            <pre>{convertHtmlToText(takeQuizQuestion["question_title"])}</pre>
          </div>
          {takeQuizQuestion?.question_image && (
            <div className="between-img">
              <img
                src={BACKEND_URL + "media/" + takeQuizQuestion?.question_image}
                alt=""
              />
            </div>
          )}
          <div className="options-div">
            <ContentEditable className="essay-question" onPaste={handlePaste}
              html={answerText}
              onKeyDown={handleEssayKeyDown}
              style={
                currentWordCount >= takeQuizQuestion?.options?.max_word
                  ? {
                    outlineColor: "red",
                    borderColor: "red",
                    backgroundColor: "#fff8f8",
                  }
                  : { color: "black" }
              }
              placeholder={convertHtmlToText(
                takeQuizQuestion?.options?.description
              )}
              onChange={handleTextChange}
              autoFocus={true}
            />
            <div
              className="word-limit"
              style={
                currentWordCount >= takeQuizQuestion?.options?.max_word
                  ? {
                    color: "red",
                          visibility: `${
                          currentWordCount >
                      takeQuizQuestion?.options?.max_word / 2 && "visible"
                      }`,
                  }
                  : {
                    color: "black",
                    visibility: `${
                      currentWordCount >
                      takeQuizQuestion?.options?.max_word / 2 && "visible"
                      }`,
                  }
              }
            >
              <span ref={wordCountRef}>{currentWordCount}</span>/{takeQuizQuestion?.options?.max_word} Words
            </div>
          </div>
          {takeQuizQuestion["show_explanation"] && (
            <div className="question-explanation">
              <div className="explanation-title">Explanation: </div>
              <div className="explanation-body">
                {convertHtmlToText(takeQuizQuestion["explanation_text"])}
              </div>
            </div>
          )}
          <button
            ref={submitButtonRef}
            className={
              answerText.length < 1 ? "btn-submit btn-disable" : "btn-submit"
            }
            onClick={handleSubmit}
          >
            {quizGeneralSettings.selectnumberofquestion === completeQuestion
              ? "Submit"
              : "Next Question"}
          </button>
        </div>
      </div>
    </div>
  );
}
