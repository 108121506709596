import '../../assets/styles/myTeam.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
export default function MyTeamModal(props){
    return (
      <>
      <div
        id="loginmodal"
        className="modalmain"
        tabIndex={-1}
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal_dialog2 " role="document">
              <div
                className="outside-column myTeamColumnOutside"
              >
                <div className='myteam_container'>
                  <div
                    className="d-table "
                    style={{ width: "104%", marginBottom: 34}}
                  >
                    <h5
                      id="h5_myteams"
                      className="d-table-cell"
                    >
                      Upgrade to create your own team!
                                        </h5>

                                        <FontAwesomeIcon
                style={{cursor:"pointer",position:"absolute"}}
                icon={faClose}
                className="close-icon"
                onClick={props.onClose}
            />
                  </div>
                  <div className="toogletimeoptions">  
                    <div className="anuallymonthlydiv">
                      <button className="monthly btntoogle">
                        <b>Monthly</b>
                      </button>
                      <button className="anually btntoogle">
                        <b>Anually</b> 
                      </button>
                    </div>
                    <h5
                      id='h5save'
                    >
                      <b>Save 30%</b>
                    </h5>
                  </div>
                  <div className="wrapper">
                    <div className="table basic">
                      <div className="head_tab">
                        <h2>
                          <b style={{ borderBottom: "4px solid #A05919" }}> Free</b>
                        </h2>
                      </div>
                      
                      <div className="aj_p">
                        <h2>
                          <b>$ 0</b>/Month
                        </h2>
                      </div>
                      <div className="aj_p">
                        <h4>Available</h4>
                      </div>
                      <ul
                        className="features_myteams"
                        style={{ marginTop: "37%", marginBottom: "-5px" }}
                      >
                        <div className="btn getStartedBtn">
                          <button>Get Started</button>
                        </div>
                      </ul>
                      <div className="inner-area">
                        <h4 style={{ opacity: "0.7" }}>
                          <b>0 Members</b>
                        </h4>
                      </div>
                    </div>
                    <div className="table premium">
                      <div className="head_tab">
                        <h2>
                          <b style={{ borderBottom: "4px solid #A05919" }}>
                            Premium
                          </b>
                        </h2>
                      </div>
                      <div className="aj_p">
                        <h2>
                          <b>$ 17</b>/ Month
                        </h2>
                      </div>
                      <div className="aj_p">
                        <h4>Paid Annually</h4>
                      </div>
                      <div className="ribbon">
                        <span>Most Popular</span>
                      </div>
                      <ul
                        className="features_myteams_myteams"
                        style={{ marginTop: "52%", marginBottom: "-54px" }}
                      >
                        <div className="btn getStartedBtn">
                          <button>Get Started</button>
                        </div>
                      </ul>
                      <div className="inner-area">
                        <h4>
                          <b>2 Members</b>
                        </h4>
                      </div>
                    </div>
                    <div className="table ultimate">
                      <div className="head_tab">
                        <h2>
                          <b style={{ borderBottom: "4px solid #A05919" }}>
                            Premium Plus
                          </b>
                        </h2>
                      </div>
                      <div className="aj_p">
                        <h2>
                          {" "}
                          <b>$ 34</b>/ Month
                        </h2>
                      </div>
                      <div className="aj_p">
                        <h4>Paid Annually</h4>
                      </div>
                      <ul
                        className="features_myteams"
                        style={{ marginTop: "52%", marginBottom: "-54px" }}
                      >
                        <div className="btn getStartedBtn">
                          <button>Get Started</button>
                        </div>
                      </ul>
                      <div className="inner-area">
                        <h4>
                          <b>5 Members</b>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div id="parent">
                    <div id="child">
                      <div>
                        <button className="parentbtn">
                          <b>Compare Plans</b>
                        </button>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
    

    );
}