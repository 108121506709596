import "../../assets/styles/quizStats.css";
import React, { useRef } from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { useState } from "react";
import { useEffect } from "react";
import QuizApi from "../../api/QuizApi";
import { Chart } from "chart.js";

export default function QuizStats() {
  const handleSelectOption = (event) => {
    if (event.target.value == "veiwmonth") {
      document.getElementById("calender").style.display = "block";
    }

    if (event.target.value == "last7days") {
      setSevenDaysChart(true);
    }
  };

  function testChangetoMonthChart() {
    setSevenDaysChart(false);
    document.getElementById("calender").style.display = "none";
  }

  const [quizData, setQuizData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isSevenDaysChart, setSevenDaysChart] = useState(true);

  useEffect(() => {
    async function loadQuizData() {
      try {
        const response = await QuizApi.get(`/quiz-result/2/`);
        setQuizData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error loading quiz data:", error);
        setError(error);
        setLoading(false);
      }
    }
    loadQuizData();
    console.log(quizData);
  }, []);

  const [chartData, setChartData] = useState({});

  const LastSevenDaysChart = () => {
    const chartRef = useRef(null);
    let passedData = [];
    let failedData = [];
    let notCompletedData = [];

    let empSal = [];
    let empAge = [];

    const data = {
      labels: [
        "Q1",
        "Q2",
        "Q3",
        "Q4",
        "Q5",
        "Q6",
        "Q7",
        "Q8",
        "Q9",
        "Q10",
        "Q11",
        "Q12",
        "Q13",
        "Q14",
        "Q15",
      ],
      datasets: [
        {
          label: "Correct",
          data: [59, 30, 60, 45, 20, 70, 25, 35, 59, 30, 45, 20, 59, 70, 45],
          backgroundColor: "#2ecc71",
        },

        {
          label: "Incorrect",
          data: [41, 70, 40, 55, 80, 30, 75, 65, 41, 70, 55, 80, 41, 30, 55],
          backgroundColor: "#e74c3c",
        },
      ],
    };
    const options = {
      legend: {
        display: true,
        labels: {
          padding: 20,
        },
      },

      scales: {
        y: {
          stacked: true,
          position: "left",
          beginAtZero: true,
          title: {
            display: true,
            text: "Number of people",
            font: {
              size: 20,
            },
          },
        },
        x: {
          stacked: true,
          beginAtZero: true,
          title: {
            display: true,
            text: "Questions",
            font: {
              size: 20,
            },
          },
        },
      },
    };

    return (
      <div style={{ width: "60vw", margin: "auto" }}>
        <Bar data={data} options={options} />
      </div>
    );
  };

  const DropOffDoughnutChart = () => {
    const data = {
      labels: ["Completed", "Not Completed"],
      datasets: [
        {
          label: "# of Votes",
          data: [19, 12],

          borderWidth: 1,
        },
      ],
    };

    const options = {
      elements: {
        center: {
          text: "deep",
        },
      },
    };

    return (
      <div style={{ width: "23vw", margin: "auto" }}>
        <Doughnut data={data} options={options} />
      </div>
    );
  };

  const MonthChart = () => {
    const chartRef = useRef(null);
    let passedData = [];
    let failedData = [];
    let notCompletedData = [];

    let empSal = [];
    let empAge = [];

    const data = {
      labels: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      datasets: [
        {
          label: "Passed",
          data: [
            98, 59, 60, 45, 82, 73, 30, 98, 59, 60, 45, 82, 73, 30, 98, 59, 60,
            45, 82, 73, 30, 98, 59, 60, 45, 82, 73, 30, 40, 20,
          ],
          backgroundColor: "#2ecc71",
        },

        {
          label: "Failed",
          data: [
            98, 59, 0, 45, 82, 33, 20, 98, 59, 60, 45, 82, 73, 30, 98, 59, 60,
            45, 82, 73, 59, 60, 45, 82, 73, 30, 98, 59, 60, 45,
          ],
          backgroundColor: "#e74c3c",
        },

        {
          label: "Not Completed",
          data: [
            13, 30, 0, 23, 15, 30, 0, 13, 30, 0, 23, 15, 30, 0, 13, 30, 0, 23,
            15, 30, 0, 13, 30, 0, 23, 15, 30, 0, 13, 30, 0, 23,
          ],
          backgroundColor: "rgb(211,211,211)",
        },
      ],
    };
    const options = {
      legend: {
        display: true,
        labels: {
          padding: 20,
        },
      },
      layout: {
        padding: {
          left: 50,
          right: 130,
          top: 0,
          bottom: 0,
        },
      },

      scales: {
        y: {
          stacked: true,
          position: "left",
          beginAtZero: true,
          title: {
            display: true,
            text: "Number of attempts",
            font: {
              size: 20,
            },
          },
        },
        x: {
          stacked: true,
          beginAtZero: true,
          title: {
            display: true,
            text: "Days",
            font: {
              size: 20,
            },
          },
        },
      },
    };

    return (
      <div style={{ width: "60vw", margin: "auto" }}>
        <Bar data={data} />
      </div>
    );
  };

  const AverageScoreLineChart = () => {
    const data = {
      labels: ["Q1", "Q2", "Q3", "Q4", "Q5", "Q6", "Q7", "Q8", "Q9", "Q10"],
      datasets: [
        {
          label: "Highest",
          data: [90, 85, 88, 90, 85, 75, 90, 100, 70, 80],
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },

        {
          label: "Average",
          data: [65, 59, 80, 81, 65, 55, 65, 66, 62, 60],
          fill: false,
          borderColor: "purple",
          tension: 0.1,
        },

        {
          label: "Lowest",
          data: [45, 50, 55, 45, 43, 47, 45, 48, 42, 49],
          fill: false,
          borderColor: "blue",
          tension: 0.1,
        },
      ],
    };

    return (
      <div style={{ width: "60vw", margin: "auto" }}>
        <Line data={data} />
      </div>
    );
  };

  const WhatTimeChart = () => {
    const chartRef = useRef(null);

    const data = {
      labels: [
        "9AM - 10AM",
        "10AM - 11AM",
        "11AM - 12PM",
        "12PM - 1PM",
        "1PM - 2PM",
        "2PM - 3PM",
        "3PM - 4PM",
        "4PM - 5PM",
      ],
      datasets: [
        {
          label: "",
          data: [98, 59, 60, 45, 82, 20, 50, 30],
          backgroundColor: "#3498db",
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          position: "left",
          beginAtZero: true,
          title: {
            display: true,
            text: "Number of attempts",
            font: {
              size: 20,
            },
          },
        },
        x: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Timing",
            font: {
              size: 20,
            },
          },
        },
      },
    };

    return (
      <div style={{ width: "60vw", margin: "auto" }}>
        <Bar data={data} ref={chartRef} options={options} />
      </div>
    );
  };

  return (
    <>
      <div className="stats-restoremodelinks">
        <div style={{ textAlign: "center" }}>
          <div
            className="stats-two1"
            style={{ textAlign: "center", borderBottom: "3px solid white" }}
          >
            <a
              href="/quiz-stats/qPYTRH"
              style={{ fontSize: 13, color: "white" }}
              id="quizstatslink"
            >
              Quiz Stats
            </a>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <div className="stats-two1" style={{ textAlign: "center" }}>
            <a
              href="/quiz-result/qPYTRH"
              style={{ fontSize: 13, color: "white" }}
              id="quizresult"
            >
              Quiz Result
            </a>
          </div>
        </div>
        <br />
      </div>
      <div
        className="stats-containermain restorescroll reportspage"
        style={{ marginTop: "4%", marginLeft: "23%" }}
      >
        <h1 style={{ marginLeft: "1%", fontWeight: 700, fontSize: 25 }}>
          Summary of:&nbsp;&nbsp;Username
        </h1>
        <div className="stats-statshead">
          <div className="stats-statsheadelements">
            Total Attempts
            <br />
            <br />
            <font className="stats-marks">112</font>
          </div>
          <div className="stats-statsheadline" />
          <div className="stats-statsheadelements">
            Quiz Completed
            <br />
            <br />
            <font className="stats-marks">3</font>
          </div>
          <div className="stats-statsheadline" />
          <div className="stats-statsheadelements">
            Passed
            <br />
            <br />
            <font className="stats-marks">0</font>
          </div>
          <div className="stats-statsheadline" />
          <div className="stats-statsheadelements">
            Failed
            <br />
            <br />
            <font className="stats-marks">3</font>
          </div>
          <div className="stats-statsheadline" />
          <div className="stats-statsheadelements">
            Average Time
            <br />
            <br />
            <font className="stats-marks">None</font>
          </div>
          <div className="stats-statsheadline" />
          <div className="stats-statsheadelements">
            Average Score
            <br />
            <br />
            <font className="stats-marks">7</font>
          </div>
        </div>
        <br />
        <div>
          <p
            style={{
              marginLeft: "1%",
              fontWeight: 700,
              fontSize: 25,
              float: "left",
            }}
          >
            Question Answering Accuracy
          </p>
        </div>
        <br />
        <br />
        <div
          style={{
            float: "right",
            height: "auto",
            width: "auto",
            marginRight: "20%",
          }}
        >
          <table
            className="stats-calender"
            id="calender"
            onClick={testChangetoMonthChart}
          >
            <tbody>
              <tr>
                <th>&lt;</th>
                <th colSpan={4}>2021</th>
                <th>&gt;</th>
              </tr>
              <tr>
                <td colSpan={2}>January</td>
                <td colSpan={2}>February</td>
                <td colSpan={2}>March</td>
              </tr>
              <tr>
                <td colSpan={2}>April</td>
                <td colSpan={2}>May</td>
                <td colSpan={2}>June</td>
              </tr>
              <tr>
                <td colSpan={2}>July</td>
                <td colSpan={2}>August</td>
                <td colSpan={2}>September</td>
              </tr>
              <tr>
                <td colSpan={2}>October</td>
                <td colSpan={2}>November</td>
                <td colSpan={2}>December</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 30,
          }}
          id="daysgraph"
        >
          <iframe
            className="stats-chartjs-hidden-iframe"
            tabIndex={-1}
            style={{
              width: "100%",
              display: "block",
              border: 0,
              height: 0,
              margin: 0,
              position: "absolute",
              inset: 0,
            }}
          />

          {isSevenDaysChart ? (
            <LastSevenDaysChart></LastSevenDaysChart>
          ) : (
            <MonthChart></MonthChart>
          )}
          <br />
        </div>
        <div
          style={{
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "none",
            marginTop: 30,
          }}
          id="monthsgraph"
        >
          <iframe
            className="stats-chartjs-hidden-iframe"
            tabIndex={-1}
            style={{
              width: "100%",
              display: "block",
              border: 0,
              height: 0,
              margin: 0,
              position: "absolute",
              inset: 0,
            }}
          />
          <canvas
            id="monthchart"
            width={0}
            height={0}
            style={{ display: "block", width: 0, height: 0 }}
          />
          <br />
        </div>
        <br />
        <div style={{ width: "100%", fontSize: 17, padding: 5 }}>
          <a href="" style={{ float: "left", marginLeft: 5 }}>
            See Passed Failed Report
          </a>

          <a href="" style={{ float: "right" }}>
            Print
          </a>
          <br />
        </div>

        <br />
        <div
          style={{
            height: 3,
            backgroundColor: "black",
            width: "100%",
            marginTop: 5,
          }}
        />
        <br />
        <p
          style={{
            marginLeft: "1%",
            fontWeight: 700,
            fontSize: 25,
            float: "left",
          }}
        >
          Number of people completed VS not completed
        </p>
        <br />
        <br />
        <br />
        <br />
        <DropOffDoughnutChart></DropOffDoughnutChart>
        <br />
        <div
          style={{
            height: 3,
            backgroundColor: "black",
            width: "100%",
            marginTop: 5,
          }}
        />
        <br />
        <p
          style={{
            marginLeft: "1%",
            fontWeight: 700,
            fontSize: 25,
            float: "left",
          }}
        >
          Highest, Lowest, and Average scores
        </p>
        <br />
        <br />
        <br />
        <br />
        <AverageScoreLineChart></AverageScoreLineChart>
        <br />
        <div
          style={{
            height: 3,
            backgroundColor: "black",
            width: "100%",
            marginTop: 5,
          }}
        />
        <br />
        <p
          style={{
            marginLeft: "1%",
            fontWeight: 700,
            fontSize: 25,
            float: "left",
          }}
        >
          Question and Answer Stats
        </p>
        <br />
        <br />

        <br />
        <div
          style={{
            height: 3,
            backgroundColor: "black",
            width: "100%",
            marginTop: 5,
          }}
        />
        <br />
        <p
          style={{
            marginLeft: "1%",
            fontWeight: 700,
            fontSize: 25,
            float: "left",
          }}
        >
          Geographical
        </p>
        <br />
        <br />
        <img src="/images/MapChart_Map.png" alt="" className="stats-worldmap" />
        <br />
        <br />
      </div>
    </>
  );
}
