const DataTemplateQuestionType = {
    "Multiple Choice Question": {
        question_type: "multiple_choice",
        question_title: "Multiple Choice Question",
        show_explanation: false,
        explanation_text: "",
        question_order: 0,
        is_mandatory: false,
        points: 20,
        negative_points: 20,
        options: {
            opt_1: "",
            opt_2: "",
        },
        correct_answers: ["opt_1"],
        question_image: "",
        quiz: 2,
    },
    "Multiple Response Question": {
        question_type: "multiple_response",
        question_title: "Multiple Response Question",
        show_explanation: false,
        explanation_text: "",
        question_order: 0,
        is_mandatory: false,
        points: 20,
        negative_points: 20,
        options: {
            opt_1: "",
            opt_2: "",
        },
        correct_answers: ["opt_1"],
        question_image: "",
        quiz: 2,
    },
    "True or False Question": {
        question_type: "true_false",
        question_title: "True or False Question",
        show_explanation: false,
        explanation_text: "",
        question_order: 0,
        is_mandatory: false,
        points: 20,
        negative_points: 20,
        options: {
            opt_1: "True",
            opt_2: "False",
        },
        correct_answers: ["opt_1"],
        question_image: "",
        quiz: 2,
    },
    "Yes or No Question": {
        question_type: "yes_no",
        question_title: "Yes or No Question",
        show_explanation: false,
        explanation_text: "",
        question_order: 0,
        is_mandatory: false,
        points: 20,
        negative_points: 20,
        options: {
            opt_1: "Yes",
            opt_2: "No",
        },
        correct_answers: ["opt_1"],
        question_image: "",
        quiz: 2,
    },
    "Essay Question": {
        question_type: "essay",
        question_title: "Essay Question",
        show_explanation: false,
        explanation_text: "",
        question_order: 0,
        is_mandatory: false,
        points: 20,
        negative_points: 20,
        options: {
            description: "",
            max_word: 200,
        },
        question_image: "",
        quiz: 2,
    },
    "Upload a File": {
        question_type: "add_document",
        question_title: "Upload a File Question",
        show_explanation: false,
        explanation_text: "",
        question_order: 0,
        is_mandatory: false,
        points: 20,
        negative_points: 20,
        options: {
            description: "",
            available_file_type: [],
        },
        question_image: "",
        quiz: 2,
    },
};

export default DataTemplateQuestionType;
