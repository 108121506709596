import React, { useEffect, useMemo, useRef, useState } from 'react';
import Sidebar from '../Common/QuizSidebar';
import QuizApi from '../../services/QuizApi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MultipleChoice from './questionTypes/MultipleChoice';
import { useDispatch, useSelector } from 'react-redux';
import YesNo from './questionTypes/YesNo';
import EditQuiz from './EditQuiz';
import TextFormatter from './TextFormatter';
import TrueFalse from './questionTypes/TrueFalse';
import MultipleResponse from './questionTypes/MultipleResponse';
import Essay from './questionTypes/Essay';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state';
import AddDocument from './questionTypes/AddDocument';
import SelectFileUploadType from './SelectFileUploadType';
import { MainNavbar } from '../Common/Navbar';
import ErrorNotificationComponent from './ErrorNotificationComponent';
import Loading from './Loading';
import { setNavbarActiveTab } from '../../state/action-creators';

export default function EditQuestions() {
  let quiz_id = useParams().id;
  let navigator = useNavigate();
  const [questionData, setQuestionData] = useState({});
  const setQuestions = useSelector((state) => state.setQuestions);
  const [question_type, setQuestion_type] = useState("");
  const [questionChangeComponent, setQuestionChangeComponent] = useState(
    <EditQuiz />
  );
  const isExplanationOn = useSelector((state) => state.isExplanationOn);
  const dispatch = useDispatch();
  const {
    setIsExplanationOn,
    setImagePath,
    setIsQuestionPopupOpen,
    setFirstQuestionId,
  } = bindActionCreators(actionCreators, dispatch);
  const isQuestionPopupOpen = useSelector((state) => state.isQuestionPopupOpen);
  const firstQuestionId = useSelector((state) => state.firstQuestionId);

  const setQuestionComponent = (question_type, question_data) => {
    setIsExplanationOn(question_data["show_explanation"]);
    switch (question_type) {
      case "multiple_choice":
        setQuestionChangeComponent(<MultipleChoice data={question_data} />);
        break;
      case "multiple_response":
        setQuestionChangeComponent(<MultipleResponse data={question_data} />);
        break;
      case "essay":
        setQuestionChangeComponent(<Essay data={question_data} />);
        break;
      case "yes_no":
        setQuestionChangeComponent(<YesNo data={question_data} />);
        break;
      case "true_false":
        setQuestionChangeComponent(<TrueFalse data={question_data} />);
        break;
      case "add_document":
        setQuestionChangeComponent(<AddDocument data={question_data} />);
        break;
      case "0":
        setQuestionChangeComponent(<EditQuiz />);
        break;
      default:
        setQuestionChangeComponent(<EditQuiz />);
        break;
    }
  };

  const location = useLocation();
  const activeComponent = useRef(null);

  useMemo(async () => {
    setQuestionChangeComponent(<Loading />);
    if (setQuestions) {
      let response = await QuizApi.get(`questions/${setQuestions}/`);
      setQuestionData({ ...response.data });
      setQuestionComponent(response.data.question_type, response.data);
      setImagePath(response?.data?.question_image);
    } else {
      setQuestionChangeComponent(<EditQuiz />);
    }
  }, [setQuestions]);

  const handleParentClick = (event) => {
    if (isQuestionPopupOpen) {
      setIsQuestionPopupOpen(false);
    }
  };


  return (
    <div onClick={handleParentClick} ref={activeComponent}>
      <MainNavbar setQuestionChangeComponent={setQuestionChangeComponent} />
      <div className="main-editquiz-container">
        <Sidebar setQuestionChangeComponent={setQuestionChangeComponent} />
        <div style={{ width: "100%", display: "grid", alignContent: "start" }}>
          {questionChangeComponent.type.name !== "NoQuestions" && (
            <TextFormatter questionChangeComponent={questionChangeComponent} />
          )}
          {
            questionChangeComponent.type.name !== "AddDocument" &&
            <ErrorNotificationComponent />
          }
          {/* {!setQuestions && <EditQuiz />} */}
          {questionChangeComponent && questionChangeComponent}
        </div>
      </div>
    </div>
  );
}
