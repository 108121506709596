import React, { useEffect, useState } from 'react';
import '../../assets/styles/components/selectFileUploadType.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { handleQuestionSubmit } from '../../utils/helpers';
import { useSelector } from 'react-redux';

export default function SelectFileUploadType(props) {
    const fileFormat = {
        "text-documents": [".doc", ".docx", ".pdf", ".txt", ".rtf"],
        "presentations": [".ppt", ".pptx"],
        "spreadsheets": [".xls", ".xlsx", ".csv"],
        "images": [".jpg", ".jpeg", ".png", ".bmp", ".gif", ".svg"],
        "audio": [".mp3", ".wav", ".acc"],
        "video": [".mp4", ".avi", ".mov", ".wmv"],
        "compressed-files": [".zip", ".rar"],
    }
    const [availableFileFormat, setAvailableFileFormat] = useState(fileFormat["text-documents"]);
    const setQuestions = useSelector(state => state.setQuestions);

    const addRemoveFileFormat = (event, isFromCross = false) => {
        if (isFromCross) {
            props.setAddedFileFormat(props.addedFileFormat.filter(value => event !== value))
            return;
        }

        if (event.target.checked) {
            props.setAddedFileFormat([...props.addedFileFormat, event.target.value]);
        } else {
            props.setAddedFileFormat(props.addedFileFormat.filter(value => event.target.value !== value));
        }
    }

    useEffect(() => {
        handleQuestionSubmit(setQuestions, {
            options: {
                description: props.description,
                available_file_type: props.addedFileFormat
            }
        })
    }, [props.addedFileFormat])
    return (
        <>
            <div className="select-file-upload-type">
                <div className="upload-title">
                    Upload Settings
                </div>
                <div className="upload-container">
                    <div className="upload-info">
                        Select the file format you would like to allow for upload
                    </div>
                    <div className="upload-type-select">
                        {
                            props.addedFileFormat.map((item, index) => (
                                <span className="selected-items">{item} <FontAwesomeIcon icon={faX} size="xs" onClick={() => { addRemoveFileFormat(item, true) }} /></span>
                            ))
                        }

                    </div>
                    <div className="file-type">
                        <span >File Type: </span>
                        <select onChange={(event) => { setAvailableFileFormat(fileFormat[event.target.value]) }}>
                            <option value="text-documents">Text Documents</option>
                            <option value="presentations">Presentations</option>
                            <option value="spreadsheets">Spreadsheets</option>
                            <option value="images">Images</option>
                            <option value="audio">Audio</option>
                            <option value="video">Video</option>
                            <option value="compressed-files">Compressed Files</option>
                        </select>
                    </div>
                    <div className="available-file-format">
                        <div>File Format: </div>
                        <div className="checkbox-div">
                            {
                                availableFileFormat.map((item, index) => (
                                    <label htmlFor={item} key={index}><input type="checkbox" value={item} id={item} onChange={addRemoveFileFormat} checked={props.addedFileFormat.includes(item)} />{item}</label>
                                ))
                            }
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}
