const initialState = {
  user: null,
  token: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: action.payload, // or appropriate user data
        token: action.payload.token,
      };
    // Handle other actions as needed
    default:
      return state;
  }
};
