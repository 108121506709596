
const getBeforeReloadActiveTab = ()=>{
    if (localStorage.getItem('before_reload_question') === 'false') {
        return "quiz";
    }
    if(localStorage.getItem('before_reload_question')){
        return "questions";
    }
    return "quiz";
}

const reducer = (state = getBeforeReloadActiveTab(), action) => {
    if (action.type === "navbarActiveTab") {
        return action.payload;
    } else {
        return state;
    }
};

export default reducer;
