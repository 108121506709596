import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/styles/components/errorNotification.css';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

export default function ErrorNotificationComponent() {
    const ErrorNotification = useSelector(state => state.errorNotification);
    const dispatch = useDispatch();
    const { setErrorNotification } = bindActionCreators(actionCreators, dispatch);

    return (
        <div className={ErrorNotification ? "main-error-notification visible" : "main-error-notification"}>
            <p className="notification-title">
                {ErrorNotification}
            </p>
            <FontAwesomeIcon icon={faX} className="notify-close" onClick={() => { setErrorNotification(null) }} />
        </div>
    )
}
