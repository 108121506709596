const getLocalData = () => {
    if(document.URL.includes('preview')){
        let local_data = sessionStorage.getItem('quizProgress');
        if(local_data){
            try {
                return parseFloat(local_data);
            } catch (error) {
                return 0;
            }
        }else{
            return 0;
        }
    }else{
        return 0;
    }
}

const reducer = (state = getLocalData(), action) => {
    if (action.type === "quizProgress") {
        return action.payload;
    } else {
        return state;
    }
};

export default reducer;
