import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useEffect, useState } from "react";
import QuizTakeNavbar from "./common/QuizTakeNavbar";
import MultipleChoiceQuestion from "./common/MultipleChoiceQuestion";
import "../../assets/styles/takeQuiz/takeCommonQuestions.css";
import { useSelector } from "react-redux";
import { actionCreators } from "../../state";
import EssayQuestion from "./common/EssayQuestion";
import Loading from "./common/Loading";
import "../../assets/styles/takeQuiz/takeQuizMain.css";
import EndQuiz from "./EndQuiz";
import { useNavigate, useParams } from "react-router-dom";
import YesNoQuestion from "./common/YesNoQuestion";
import TrueFalseQuestion from "./common/TrueFalseQuestion";
import MultipleResponseQuestion from "./common/MultipleResponseQuestion";
import AddDocumentQuestion from "./common/AddDocumentQuestion";
import QuizApi from "../../services/QuizApi";
import OfflineUserDialogue from "./OfflineUserDialogue";

export default function TakeQuizMain() {
  const dispatch = useDispatch();
  const takeQuizQuestion = useSelector((state) => state.takeQuizQuestion);
  const { setTakeQuizQuestion, setQuizBrowserSettings, setIsTimeOutForceSubmit, setIsSecuritySubmit } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const [questionComponent, setQuestionComponent] = useState("");
  const isLoading = useSelector((state) => state.isLoading);
  const quizBrowserSettings = useSelector((state) => state.quizBrowserSettings);
  const [isTabChanging, setIsTabChanging] = useState(false);
  const [tabChangingTime, setTabChangingTime] = useState(10);
  const { quizId } = useParams();
  let navigate = useNavigate();
  const [timeInterval, setTimeInterval] = useState(false);
  const [isMouseLeaveOn, setIsMouseLeaveOn] = useState(false);
  const quizResult = useSelector((state) => state.quizResult);

  const [securityAlert, setSecurityAlert] = useState(0);


  useEffect(() => {
    switch (takeQuizQuestion?.question_type) {
      case "multiple_choice":
        setQuestionComponent(<MultipleChoiceQuestion />);
        break;
      case "yes_no":
        setQuestionComponent(<YesNoQuestion />);
        break;
      case "essay":
        setQuestionComponent(<EssayQuestion />);
        break;
      case "true_false":
        setQuestionComponent(<TrueFalseQuestion />);
        break;
      case "multiple_response":
        setQuestionComponent(<MultipleResponseQuestion />);
        break;
      case "add_document":
        setQuestionComponent(<AddDocumentQuestion />);
        break;
      case "end_quiz":
        setQuestionComponent(<EndQuiz setIsMouseLeaveOn={setIsMouseLeaveOn} setIsTabChanging={setIsTabChanging} customMouseLeaveEvent={customMouseLeaveEvent} />);
        break;
      default:
        navigate(`/quiz-title/${quizId}`);
        break;
    }
  }, [takeQuizQuestion]);

  useEffect(() => {
    if (isLoading) {
      setQuestionComponent(<Loading />);
    }
  }, [isLoading]);

  const customMouseLeaveEvent = (e) => {
    if (!isTabChanging) {
      setIsTabChanging(true);
    }
  };



  useEffect(() => {
    document.addEventListener("contextmenu", function (event) {
      event.preventDefault();
    });    

    document.addEventListener("dragstart", (e) => {
      e.preventDefault();
    });


    document.addEventListener("keydown", function (event) {
      if (
        event.ctrlKey &&
        (event.key === "r" || event.key === "R" || event.shiftKey)
      ) {
        event.preventDefault();
      }
    });

    if (quizBrowserSettings?.disableprint) {
      document.body.classList.add("disable-print");
      document.addEventListener("keydown", (e) => {
        if (e.keyCode === 80 && (e.ctrlKey || e.metaKey)) {
          e.preventDefault();
          if (e.shiftKey) {
            e.stopPropagation();
          }
        }
      });
    }
    if (quizBrowserSettings?.disablecopy) {
      document.addEventListener("keydown", (e) => {
        if (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) {
          e.preventDefault();
          if (e.shiftKey) {
            e.stopPropagation();
          }
        }
      });
      document.addEventListener("copy", function (event) {
        event.preventDefault();
      });
    }

    if (quizBrowserSettings?.switchingbrowsertabs) {
      document.documentElement.addEventListener("mouseleave", customMouseLeaveEvent);
      setIsMouseLeaveOn(true);
    }
  }, []);

  const intervalForTabChange = () => {
    let tabTime = 9;
    let myTimeInterval = setInterval(() => {
      setTabChangingTime(tabTime);
      tabTime--;
      if (tabTime < 0) {
        clearInterval(myTimeInterval);
        setIsTimeOutForceSubmit(true);
        setIsSecuritySubmit(true);
        setIsTabChanging(false);
        setIsMouseLeaveOn(false);
        setTabChangingTime(10);
        document.removeEventListener("mouseleave", customMouseLeaveEvent);
      }
    }, 1000);
    setTimeInterval(myTimeInterval);
  };


  const updateSecurityAlert = () => {
    if (takeQuizQuestion?.question_type !== "end_quiz"){
      QuizApi.patch(`/quiz-result/${quizResult?.id}/`, { securityalerts: securityAlert + 1 });
      setSecurityAlert(securityAlert + 1);
    }
  };

  useEffect(() => {
    if (isTabChanging) {
      if (isMouseLeaveOn) {
        updateSecurityAlert();
        intervalForTabChange();
      }
    }
    else {
      clearInterval(timeInterval);
    }

  }, [isTabChanging]);

  const handleTimeOutOk = () => {
    setTabChangingTime(10);
    setIsTabChanging(false);
  };

  return (
    <>
      {takeQuizQuestion?.question_type !== "end_quiz" && <QuizTakeNavbar />}
      {(isTabChanging && isMouseLeaveOn && takeQuizQuestion?.question_type !== "end_quiz") && (
        <div className="upper-main-timeout">
          <div className="main-tab-change">
            <div className="title">
              Navigation away from this page is not allowed
            </div>
            <div className="warn">
              Please revert back to your quiz window or you will lose your
              progress and your quiz will be submitted automatically.
            </div>
            <div className="timeout">
              <p>{tabChangingTime} Seconds remaining.</p>
              <button
                onClick={handleTimeOutOk}
              >
                ok
              </button>
            </div>
          </div>
        </div>
      )}
      {questionComponent && questionComponent}
      <OfflineUserDialogue setIsMouseLeaveOn={setIsMouseLeaveOn} setIsTabChanging={setIsTabChanging} />
        </>
    
  );
}
