
import QuizApi from '../../services/QuizApi';
import '../../assets/styles/Settings.css';
import { Link, useParams } from 'react-router-dom';
import { MainNavbar } from '../Common/Navbar';
import React, { useEffect, useState } from 'react';
import Sidebar from '../Common/Sidebar';

function LeadIntegration() {
  const { quizId } = useParams();
  const [settings, setSettings] = useState(null);
  const links = [
    { to: `/general-settings/${quizId}`, text: 'General Settings' },
    { to: `/scoring-option/${quizId}`, text: 'Scoring Options' },
    { to: `/quiz-tracking/${quizId}`, text: 'Quiz Tracking' },
    { to: `/security-settings/${quizId}`, text: 'Browser Restrictions' },
    { to: `/smart-proctoring-system/${quizId}`, text: 'Proctoring and Security' },
    { to: `/lead-integration/${quizId}`, text: 'Lead Integration' },
  ];

  useEffect(() => {
    // Fetch quiz settings using the QuizApi instance
    try {
      QuizApi.get(`/quiztracking/${quizId}/`)
        .then((response) => {
          setSettings(response.data);
        })
    }
    catch (error) {
      console.error('Error getting quiz settings:', error);
    }

  }, [quizId]);

  return (
    <div>
      {settings ? (
        <div>
          <MainNavbar />
          <Sidebar quiztitle="Your Quiz Title" links={links} />
          <div className="containermain quizsettings" >
            <br></br>
            <div style={{ width: "95%", fontSize: "17px" }}>
              Quiz Secure provides easy integration with popular 3rd party lead generation tools.
              Capture your Quiz Taker's information (name, email, etc.) and add it as a lead. Once
              integration is complete, you can automatically start populating your mailing list
              with details of all the learners that take your quiz.
            </div><br></br>

            <table className="leadgenerationtable">
              <tbody>

                <tr>
                  <th colSpan="3">Select the integration option:</th>
                </tr>
                <tr>
                  <td><input type="radio" name="settings" id=""></input></td>
                  <td>MailChimp</td>
                  <td>API Key : <b>352c25c1eaf2a0fdfec136ce723e89b8</b></td>
                </tr>
                <tr>
                  <td><input type="radio" name="settings" id=""></input></td>
                  <td>Constant Contact</td>
                  <td></td>
                </tr>
                <tr>
                  <td><input type="radio" name="settings" id=""></input></td>
                  <td>Zapier</td>
                  <td></td>
                </tr>
                <tr>
                  <td><input type="radio" name="settings" id=""></input></td>
                  <td>Active Campaign</td>
                  <td>API Key: <b>352c25c1eaf2a0fdfec136ce723e89b8</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <p>Loading settings...</p>
      )}
    </div>
  );
}

export default LeadIntegration;