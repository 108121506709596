import { combineReducers } from "redux";
import setQuestionReducer from "./setQuestionReducer";
import setIsExplanationOnReducer from "./setIsExplanationOnReducer";
import setImagePathReducer from "./setImagePathReducer";
import setIsQuestionPopupOpenReducer from "./setIsQuestionPopupOpenReducer";
import {
  setIsTimeOutForceSubmit,
  setQuestionsInSideBar,
  setQuizProgress,
} from "../action-creators";
import setFirstQuestionIdReducer from "./setFirstQuestionIdReducer";
import setNavbarActiveTabReducer from "./setNavbarActiveTabReducer";
import setQuizTitleReducer from "./setQuizTitleReducer";
import setIsFromCreateQuizReducer from "./setIsFromCreateQuizReducer";
import setErrorNotificationReducer from "./setErrorNotificationReducer";
import setQuizQuestionsReducer from "./setQuizQuestionsReducer";
import setQuizProgressReducer from "./setQuizProgressReducer";
import setTakeQuizQuestionReducer from "./setTakeQuizQuestionReducer";
import setQuizGeneralSettingsReducer from "./setQuizGeneralSettingsReducer";
import setQuizBrowserSettingsReducer from "./setQuizBrowserSettingsReducer";
import setQuizResultReducer from "./setQuizResultReducer";
import setCompleteQuestionReducer from "./setCompleteQuestionsReducer";
import setIsLoadingReducer from "./setIsLoadingReducer";
import setIsTimeOutForceSubmitReducer from "./setIsTimeOutForceSubmitReducer";
import { authReducer } from "./setLoginSuccessReducer";
import setIsSecuritySubmitReducer from "./setIsSecuritySubmitReducer";
// import setFirstQuestionIdReducer from "./setFirstQuestionIdReducer";
// import setNavbarActiveTabReducer from "./setNavbarActiveTabReducer";
// import setQuizTitleReducer from "./setQuizTitleReducer";
// import setIsFromCreateQuizReducer from "./setIsFromCreateQuizReducer";
import setQuestionsInSideBarReducer from "./setQuestionsInSideBarReducer";

const reducers = combineReducers({
  setQuestions: setQuestionReducer,
  isExplanationOn: setIsExplanationOnReducer,
  imagePath: setImagePathReducer,
  isQuestionPopupOpen: setIsQuestionPopupOpenReducer,
  questionsInSideBar: setQuestionsInSideBarReducer,
  firstQuestionId: setFirstQuestionIdReducer,
  navbarActiveTab: setNavbarActiveTabReducer,
  quizTitle: setQuizTitleReducer,
  isFromCreateQuiz: setIsFromCreateQuizReducer,
  errorNotification: setErrorNotificationReducer,
  quizProgress: setQuizProgressReducer,
  takeQuizQuestion: setTakeQuizQuestionReducer,
  quizGeneralSettings: setQuizGeneralSettingsReducer,
  quizBrowserSettings: setQuizBrowserSettingsReducer,
  quizResult: setQuizResultReducer,
  completeQuestion: setCompleteQuestionReducer,
  isLoading: setIsLoadingReducer,
  isTimeOutForceSubmit: setIsTimeOutForceSubmitReducer,
  quizQuestions: setQuizQuestionsReducer,
  LOGIN_SUCCESS: authReducer,
  isSecuritySubmit: setIsSecuritySubmitReducer,
});

export default reducers;
