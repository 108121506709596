import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./state/store";
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";

if (process.env.NODE_ENV !== "development") {
   
Sentry.init({
    dsn: "https://4b016f80c36adf60e691abb6d7b83d62@o4506269455286272.ingest.sentry.io/4506269459480576",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay()
    ],
  
    tracesSampleRate: 1.0,
  
    tracePropagationTargets: ["localhost", "192.168.1.94"],
  
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

