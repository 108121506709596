import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import QuizApi from "../../services/QuizApi";
import { useNavigate, useParams } from "react-router-dom";
import { handleQuizSubmit } from "../../utils/helpers";
import ContentEditable from "react-contenteditable";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { getHTMLtoText } from "../../utils/generalHelp";

export default function EditQuiz() {
  let navigator = useNavigate();
  const [quizdata, setQuizData] = useState();
  const [quizlogo, Setquizlogo] = useState();
  const [titleimage, settitleimage] = useState();
  // const [quizTitle, setQuizTitle] = useState('');
  const quizTitleRef = useRef(null);
  const { quizId } = useParams();
  const dispatch = useDispatch();
  const { setQuizTitle } = bindActionCreators(actionCreators, dispatch);
  const quizTitle = useSelector((state) => state.quizTitle);
  const { setIsFromCreateQuiz } = bindActionCreators(actionCreators, dispatch);
  const isFromCreateQuiz = useSelector((state) => state.isFromCreateQuiz);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showNotCharacterAllowed, setShowNotCharacterAllowed] = useState(false);

  useEffect(() => {
    async function LoadQuizData() {
      try {
        const quiz = await QuizApi.get(`quiz/${quizId}/`);
        setQuizData(quiz.data);
        Setquizlogo(quiz.data.quizlogo);
        settitleimage(quiz.data.titleimage);
        setQuizTitle(quiz.data.quiztitle);
      } catch (error) {
        console.log("evvv>", error);
      }
    }
    LoadQuizData();
    if (quizTitleRef.current) {
      quizTitleRef.current.el.current.focus();
    }
  }, []);

  const handleTitleBlur = (event) => {
    if (event.target.innerHTML.length < 1 && isFromCreateQuiz) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
    }
    if (event.target.innerHTML.length >= 1 && !isFromCreateQuiz) {
      handleQuizSubmit(quizId, { quiztitle: event.target.innerHTML }).then(
        (data) => {
          setQuizData({ ...data });
          setQuizTitle(data.quiztitle);
        }
      );
    }
  };
  const handleDescriptionChange = (event) => {
    setQuizData({ ...quizdata, quizdescription: event.target.innerHTML });
    handleQuizSubmit(quizId, { quizdescription: event.target.innerHTML });
  };

  const handleTitleButtonChange = (event) => {
    setQuizData({ ...quizdata, titlebutton: event.target.innerHTML });
    handleQuizSubmit(quizId, { titlebutton: event.target.innerHTML });
  };

  const handleQuizLogoChange = (event) => {
    handleQuizSubmit(quizId, { quizlogo: event.target.files[0] }).then(
      (data) => {
        if (data) {
          Setquizlogo(data.quizlogo);
        }
      }
    );
  };

  const handleTitleImageChange = (event) => {
    setQuizData({ ...quizdata, titleimage: event.target.files[0] });
    handleQuizSubmit(quizId, { titleimage: event.target.files[0] }).then(
      (data) => {
        if (data) {
          settitleimage(data.titleimage);
        }
      }
    );
  };

  const removeQuizLogo = () => {
    Setquizlogo("");
    setQuizData({ ...quizdata, quizlogo: "" });
    handleQuizSubmit(quizId, { quizlogo: "" });
  };

  const removeTitleImage = () => {
    settitleimage("");
    setQuizData({ ...quizdata, titleimage: "" });
    handleQuizSubmit(quizId, { titleimage: "" });
  };

  const handleTitleChange = (event) => {
    if (isFromCreateQuiz && quizTitle) {
      setIsFromCreateQuiz(false);
    }

    if (showErrorMessage) {
      setShowErrorMessage(false);
    }

    if (event.currentTarget.innerHTML.length < 61) {
      setQuizTitle(event.currentTarget.innerHTML);
    } else {
      setQuizTitle(event.currentTarget.innerHTML.substring(0, 60));
    }
  };

  const handleStartButtonChange = (event) => {
    setQuizData({ ...quizdata, titlebutton: event.currentTarget.innerHTML });
  };

  const notAllowCharacter = ["<", ">", "{", "}", "[", "]", "^", "`", "|", "\\", "/"];

  const handleKeyDown = (event) => {
    setShowNotCharacterAllowed(false);
    if (notAllowCharacter.includes(event.key)) {
      event.preventDefault();
      setShowNotCharacterAllowed(true);
    }
    if (event.currentTarget.innerHTML.length >= 60 && event.keyCode !== 8) {
      event.preventDefault();
      event.stopPropagation();
      event.target.blur();
    }
  };

  const handleTitlePaste = (event) => {
    let text = getHTMLtoText(event.clipboardData.getData(event.clipboardData.types[0]));
    if (event.clipboardData.types[0].includes("html")) {
      text = getHTMLtoText(text)
    }

    if(text.match(/.*[\[\]<>\{\}\^\`|\\/].*/)){
      setShowNotCharacterAllowed(true);
      event.preventDefault();
      document.execCommand("insertHTML", false, text.replace(/[<>\{\}\[\]\^`\|\\\/]/g, ""));
    }

    return true

  }

  return (
    <div className="main-editquiz-container">
      <div className="editing-area">
        <div className="editing-container">
          {
            <>
              {quizlogo ? (
                <div className="quiz-image-container">
                  <button
                    type="button"
                    className="remove-image-button image-upload-button"
                    onClick={removeQuizLogo}
                  >
                    Remove
                  </button>
                  <img src={quizlogo} alt="quizLogo" className="quiz-images" />
                </div>
              ) : (
                <label
                  className="image-upload-button"
                  style={{ width: "200px" }}
                >
                  Upload Your Logo (Optional)
                  <input
                    type="file"
                    name="quizlogo"
                    id=""
                    onChange={handleQuizLogoChange}
                    src={quizlogo}
                  />
                </label>
              )}
            </>
          }
          <ContentEditable
            onFocus={(event) =>
              window.getSelection().selectAllChildren(event.target)
            }
            className="title-editor title"
            onChange={handleTitleChange}
            placeholder="Add a title"
            onBlur={handleTitleBlur}
            html={quizTitle}
            ref={quizTitleRef}
            onKeyDown={handleKeyDown}
            onPaste={handleTitlePaste}
          />
          {showNotCharacterAllowed && (
            <p className="not-char-allowed-msg">The quiz title should not contain any of the following characters: {notAllowCharacter.join(", ")}</p>
          )}
          {(!quizTitle && !isFromCreateQuiz) || showErrorMessage ? (
            <p className={"quiz-title-error visible"}>
              {" "}
              Please enter a title for your quiz to proceed.
            </p>
          ) : (
            <p className={"quiz-title-error"}>
              {" "}
              Please enter a title for your quiz to proceed.
            </p>
          )}
          {
            <>
              {titleimage ? (
                <div className="quiz-image-container">
                  <button
                    type="button"
                    className="remove-image-button image-upload-button"
                    onClick={removeTitleImage}
                  >
                    Remove
                  </button>
                  <img
                    src={titleimage}
                    alt="quizTitleImage"
                    className="quiz-images"
                  />
                </div>
              ) : (
                <label className="image-upload-button button-title-upload-image">
                  Upload image to display between
                  <br />
                  your question and answer (Optional)
                  <input
                    type="file"
                    name="quiztitleimage"
                    id=""
                    onChange={handleTitleImageChange}
                    src={titleimage}
                  />
                </label>
              )}
            </>
          }
          <div
            contentEditable={true}
            className="title-editor description"
            suppressContentEditableWarning={true}
            placeholder="Type your description"
            onFocus={(event) =>
              window.getSelection().selectAllChildren(event.target)
            }
            onBlur={handleDescriptionChange}
          >
            {quizdata?.quizdescription}
          </div>
          <ContentEditable
            className="start-quiz-button-editor"
            onFocus={(event) =>
              window.getSelection().selectAllChildren(event.target)
            }
            onBlur={handleTitleButtonChange}
            onChange={handleStartButtonChange}
            html={quizdata?.titlebutton?quizdata?.titlebutton:"Start"}
          ></ContentEditable>
        </div>
      </div>
    </div>
  );
}
