import { useEffect, useRef } from 'react';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { BACKEND_URL } from '../../../services/QuizApi';
import { setQuizGeneralSettings } from '../../../state/action-creators';
import { handleTakeNextQuestion } from '../../../utils/helpers';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state';

export default function AddDocumentQuestion() {
    const takeQuizQuestion = useSelector((state) => state.takeQuizQuestion);
    const [isFirst, setIsFirst] = useState(true);
    const [uploadFile, setUploadFile] = useState("");
    const completeQuestion = useSelector((state) => state.completeQuestion);
    const isTimeOutForceSubmit = useSelector((state) => state.isTimeOutForceSubmit);
    const quizGeneralSettings = useSelector((state) => state.quizGeneralSettings);
    const [fileIsToLarge, setFileIsToLarge] = useState(false);

    const dispatch = useDispatch();
    const { setQuizProgress } = bindActionCreators(actionCreators, dispatch);

    const submitButtonRef = useRef(null);

    const convertHtmlToText = (text) => {
        const ele = document.createElement('div');
        ele.innerHTML = text;
        return ele.innerText;
    }

    const uploadFileHandler = (event) => {
        setFileIsToLarge(false);
        try {
            if (event?.target?.files[0]?.size > (100 * 1024 * 1024)) {
                setFileIsToLarge(true);
                return;
            }
        } catch (error) {
            console.log(error);
            return;
        }

        if(event.target.files[0]){
            setUploadFile(event.target.files[0]);
        }
        if (isFirst) {
            setQuizProgress(
                (completeQuestion / quizGeneralSettings.selectnumberofquestion) * 100
            );
            sessionStorage.setItem("quizProgress",(completeQuestion / quizGeneralSettings.selectnumberofquestion) * 100);
            setIsFirst(false);
        }
    }

    const handleSubmit = (event) => {
        handleTakeNextQuestion(takeQuizQuestion["question_id"], [uploadFile], true)
        setIsFirst(false);
        event.preventDefault();
    }

    useEffect(() => {
        if (isTimeOutForceSubmit) {
            submitButtonRef?.current?.click();
        }

    }, [isTimeOutForceSubmit])


    return (
        <div className="quizcontainer">
            <div className="quizcontainer">
                <div className="take-common-questions">
                    <div className="question-title">
                        <pre>{convertHtmlToText(takeQuizQuestion["question_title"])}</pre>
                    </div>
                    {takeQuizQuestion?.question_image && (
                        <div className="between-img">
                            <img
                                src={BACKEND_URL + "media/" + takeQuizQuestion?.question_image}
                                alt="" />
                        </div>
                    )}
                    <div className="options-div upload-file">
                        <input type="file" id="upload-file" onChange={uploadFileHandler} accept={takeQuizQuestion?.options?.available_file_type.map(value => (value))} />
                        <label className="file-label" htmlFor="upload-file">{Boolean(uploadFile) ? uploadFile?.name : "Select file to upload"}</label>
                        {
                            fileIsToLarge && (
                                <p className="large-file-error">File too large. Please upload file less than 100 MB.</p>
                            )
                        }
                        <p className="size-limit">File size limit: 100 MB</p>
                        <p className="file-type">Accepted file types: {takeQuizQuestion?.options?.available_file_type.map(value => (value + '  '))} {(takeQuizQuestion?.options?.available_file_type.length === 0) && "All"}  </p>
                    </div>
                    {takeQuizQuestion?.options["description"] && (
                        <div className="question-explanation">
                            <div className="explanation-body description">
                                {convertHtmlToText(takeQuizQuestion?.options["description"])}
                            </div>
                        </div>
                    )}
                    <button
                        className={Boolean(uploadFile) ? "btn-submit" : "btn-submit btn-disable"}
                        ref={submitButtonRef}
                        onClick={handleSubmit}
                    >

                        {quizGeneralSettings?.selectnumberofquestion === completeQuestion
                            ? "Submit"
                            : "Next Question"}
                    </button>
                </div>
            </div>
        </div>
    )
}
