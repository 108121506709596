import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/styles/takeQuiz/endQuiz.css';
import QuizApi from '../../services/QuizApi';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state';
import { useNavigate } from 'react-router-dom';
import Loading from './common/Loading';

export default function EndQuiz(props) {
    const takeQuizQuestion = useSelector(state => state.takeQuizQuestion);
    const dispatch = useDispatch();
    const { setTakeQuizQuestion, setIsTimeOutForceSubmit,setQuizGeneralSettings, setQuizBrowserSettings, setQuizResult, setCompleteQuestion, setQuizProgress } = bindActionCreators(actionCreators, dispatch);
    let navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(true);
    const [isInstructionShown, setIsInstructionShown] = useState(false);
    const firstQuestion = useRef(null);

    const handleNextQuiz = (e) => {
        setIsLoaded(true);
        sessionStorage.setItem("quizStartTime",new Date().getTime());
        QuizApi.post(`quiz-initiate/`, { ...takeQuizQuestion?.user_data, quizid: takeQuizQuestion?.next_quiz_id })
            .then((response) => {
                setQuizGeneralSettings(response.data?.general_settings);
                setQuizBrowserSettings(response.data?.browser_settings);
                setQuizResult(response.data?.result_data);
                setCompleteQuestion(1);
                setQuizProgress(0);
                setIsTimeOutForceSubmit(false);
                sessionStorage.setItem('takeQuizQuestion', JSON.stringify(response.data?.first_question));
                sessionStorage.setItem('quizGeneralSettings', JSON.stringify(response.data?.general_settings));
                sessionStorage.setItem('quizBrowserSettings', JSON.stringify(response.data?.browser_settings));
                sessionStorage.setItem('quizResult', JSON.stringify(response.data?.result_data));
                sessionStorage.setItem('completeQuestions', 1);
                sessionStorage.setItem("min", response.data?.general_settings?.minutes);
                sessionStorage.setItem("sec", 0);
                sessionStorage.setItem("quizProgress", 0);
                props?.setIsTabChanging(false);
                props?.setIsMouseLeaveOn(false);
                if(takeQuizQuestion?.transition === "automatic"){
                    setTakeQuizQuestion(response.data?.first_question);
                    navigate(`/preview/${takeQuizQuestion?.next_quiz_title.replace(/\s+/g, '-').replaceAll("/", "-")}`);
                    if(response.data?.browser_settings?.switchingbrowsertabs){
                        document.documentElement.addEventListener("mouseleave", props.customMouseLeaveEvent);
                        props?.setIsMouseLeaveOn(true);
                    }else{
                        props?.setIsMouseLeaveOn(false);
                    }
                }else{
                    if(response.data?.browser_settings?.switchingbrowsertabs){
                        setIsInstructionShown(true);
                        setTakeQuizQuestion(response.data?.first_question);
                        firstQuestion.current = response.data?.first_question;
                        props?.setIsMouseLeaveOn(true);
                        props?.setIsTabChanging(false);
                        document.documentElement.addEventListener("mouseleave", props.customMouseLeaveEvent);
                        navigate(`/preview/${takeQuizQuestion?.next_quiz_title.replace(/\s+/g, '-').replaceAll("/", "-")}`);
                    }else{
                        setTakeQuizQuestion(response.data?.first_question);
                        navigate(`/preview/${takeQuizQuestion?.next_quiz_title.replace(/\s+/g, '-').replaceAll("/", "-")}`);
                        props?.setIsMouseLeaveOn(false);
                    }
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        if (takeQuizQuestion?.show_next_quiz) {
            if (takeQuizQuestion?.transition === "automatic") {
                handleNextQuiz();
            }
        }
        setIsLoaded(false);
    }, []);

    return (
        <>
            {
                isLoaded ? <Loading/> : (<>
                    <div className="main-end-quiz">
                        {
                            takeQuizQuestion?.showcustommsg &&
                            <div className="custom-message">
                                <pre>
                                    {takeQuizQuestion?.custommessage}
                                </pre>
                            </div>
                        }
                        {
                            takeQuizQuestion?.showpassfail &&
                            <div className="pass-fail">
                                {takeQuizQuestion?.pass_fail_message}
                            </div>
                        }
                        {
                            takeQuizQuestion?.showscore &&
                            <div className="show-score">
                                {takeQuizQuestion?.scorecustommessage}
                            </div>
                        }

                        {
                            takeQuizQuestion?.show_next_quiz &&
                            <>
                                <div className="head-next-quiz">
                                    You may now take the next quiz.
                                </div>
                                <div className="next-quiz">
                                    <div className="next-quiz-title">
                                        {takeQuizQuestion?.next_quiz_title}
                                    </div>
                                    <hr />
                                    <div className="info-table">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Total questions:</td>
                                                    <td>{takeQuizQuestion?.next_quiz_total_questions}</td>
                                                </tr>
                                                <tr>
                                                    <td>Time limit:</td>
                                                    <td>{takeQuizQuestion?.next_quiz_time}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <button className="start-next-quiz" onClick={()=>{ takeQuizQuestion?.browser_settings?.switchingbrowsertabs ? setIsInstructionShown(true): handleNextQuiz() }} >{takeQuizQuestion?.takenextquizbutton}</button>
                                </div>
                            </>
                        }

                    {
                        isInstructionShown &&
                        <div className="quizEndPage">

                            <div className="instructions">
                                <div className="child-instruct">
                                    <h1>Important: Please Read Before Starting!</h1>
                                    <div className="instruct">
                                        <>
                                            <p>Once you start, you must remain on this quiz tab. Switching to another tab or application may result in your quiz being automatically submitted.</p>
                                            <br />
                                        </>
                                        <p> If there's a timer for this quiz, keep an eye on it. Once the time is up, your quiz will be automatically submitted.</p><br />
                                    </div>
                                    <button className="start-quiz-btn" onClick={() => {handleNextQuiz()}} >Start the Quiz</button>
                                </div>

                            </div>
                        </div>
                    }
                    </div>
                </>)}
        </>
    )
}
