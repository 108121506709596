import QuizApi from "../services/QuizApi";
import { store } from "../state/store";
import {
    setErrorNotification,
    setTakeQuizQuestion,
    setCompleteQuestion,
    setIsLoading,
    quizQuestionId,
    setQuizQuestions,
    setNavbarActiveTab,
    setFirstQuestionId,
    setIsSecuritySubmit,
    setImagePath,
} from "../state/action-creators";

export async function handleQuestionSubmit(question_id, question_body) {
    let formData = new FormData();
    for (let key in question_body) {
        if (key === "correct_answers" || key === "options") {
            formData.append(key, JSON.stringify(question_body[key]));
        } else {
            formData.append(key, question_body[key]);
        }
    }

    try {
        const res = await QuizApi.patch(`questions/${question_id}/`, formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });

        return res.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function handleQuizSubmit(quiz_id, quiz_body) {
    let formData = new FormData();
    for (let key in quiz_body) {
        formData.append(key, quiz_body[key]);
    }

    try {
        const resp = await QuizApi.patch(`quiz/${quiz_id}/`, formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });
        return resp.data;
    } catch (error) {
        Object.keys(error.response.data).forEach((key) => {
            let message = error.response.data[key];
            if (message[0] === "The submitted file is empty.") {
                message =
                    "This is not a valid image. Please upload a JPG, JPEG, PNG, GIF, or BMP image.";
            }
            store.dispatch(setErrorNotification(message));
        });
        console.log(error);
        return null;
    }
}

export async function handleDeleteQuestion(question_id) {
    try {
        const resp = await QuizApi.delete(`questions/${question_id}/`);
        let all_questions = document.getElementsByClassName(
            "question-title-side"
        );
        let next_question_id = false;
        for (let i = 0; i < all_questions.length; i++) {
            if (all_questions.length === 1) {
                break;
            }
            const question = all_questions[i];
            if (
                parseInt(question.getAttribute("data-question-id")) ===
                parseInt(question_id)
            ) {
                if (i === all_questions.length - 1) {
                    next_question_id =
                        all_questions[i - 1].getAttribute("data-question-id");
                    break;
                }
                next_question_id =
                    all_questions[i + 1].getAttribute("data-question-id");
                break;
            }
        }
        store.dispatch(quizQuestionId(next_question_id));
        let quizQuestions = store.getState().quizQuestions;
        quizQuestions = quizQuestions.filter((ele) => {
            return ele.id !== parseInt(question_id);
        });
        store.dispatch(setQuizQuestions(quizQuestions));
        if (!next_question_id) {
            store.dispatch(setNavbarActiveTab("quiz"));
            store.dispatch(setQuizQuestions({}));
            store.dispatch(setFirstQuestionId(0));
        }
        return resp.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function handleTakeNextQuestion(
    question_id,
    answers,
    answer_is_file = false
) {
    store.dispatch(setIsLoading(true));
    let formData = {
        answer: answers,
        question_id: parseInt(question_id),
        quizresult_id: store.getState().quizResult.id,
    };

    if (store.getState().quizProgress === 100) {
        formData["is_last_question"] = true;
    }

    if (store.getState().isTimeOutForceSubmit) {
        formData["is_last_question"] = true;
        formData["is_timeout"] = true;
    }

    if (store.getState().isSecuritySubmit) {
        formData["is_security_submit"] = true;
        formData["is_timeout"] = false;
    }

    let content_type = "application/json";
    if (answer_is_file) {
        formData = new FormData();
        formData.append("answer", JSON.stringify([" "]));
        formData.append("file_answer", answers[0]);
        formData.append("question_id", parseInt(question_id));
        formData.append("quizresult_id", store.getState().quizResult.id);
        content_type = "multipart/form-data";
        if (store.getState().quizProgress === 100) {
            formData.append("is_last_question", true);
        }

        if (store.getState().isTimeOutForceSubmit) {
            formData.append("is_last_question", true);
            formData.append("is_timeout", true);
        }

        if(store.getState().isSecuritySubmit){
            formData.append("is_security_submit", true);
            formData.append("is_timeout",false);
        }
    }

    try {
        const resp = await QuizApi.post(`answers/`, formData, {
            headers: {
                "content-type": content_type,
            },
        });
        store.dispatch(setTakeQuizQuestion(resp.data));
        sessionStorage.setItem("takeQuizQuestion", JSON.stringify(resp.data));
        let setQuNumber = store.getState().completeQuestion + 1;
        store.dispatch(setCompleteQuestion(setQuNumber));
        sessionStorage.setItem("completeQuestions", setQuNumber);
        store.dispatch(setIsLoading(false));
        store.dispatch(setIsSecuritySubmit(false));
        return resp.data;
    } catch (error) {
        store.dispatch(setIsLoading(false));
        console.log(error);
        setTimeout(() => {
            handleTakeNextQuestion(question_id, answers, answer_is_file);
        }, 2000);
        return null;
    }
}

export const removeExtraCss = (element) => {
    console.log(element);
    let elementTree = element.getElementsByTagName("*");
    let res = "";
    for (let i = 0; i < elementTree.length; i++) {
        let ele = elementTree[i];
        console.log(ele.innerText, ele.style.fontWeight);
        ele.removeAttribute("style");
        ele.removeAttribute("class");
        res = res + ele;
    }

    return res;
};

export const handleQuestionImageUpload = async ({question_id, image}) => {
    let formData = new FormData();
    formData.append("question_image", image);
    try {
        const response = await QuizApi.patch(
            `questions/${question_id}/`,
            formData,
            {
                headers: {
                    "content-type": "multipart/form-data",
                },
            }
        );
        store.dispatch(setImagePath(response.data.question_image));
    } catch (error) {
        let message = error.response.data["question_image"];
        if (
            message[0] ===
                "The submitted file is empty." ||
            message[0] ===
                "Upload a valid image. The file you uploaded was either not an image or a corrupted image."
        ) {
            message =
                "This is not a valid image. Please upload a JPG, JPEG, PNG, GIF, or BMP image.";
        }
        store.dispatch(setErrorNotification(message));
    }
};