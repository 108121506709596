export const quizQuestionId = (questionId) => {
    return (dispatch) => {
        dispatch({
            type: "setQuestionId",
            payload: questionId,
        });
    };
};

export const setIsExplanationOn = (isOn) => {
    return (dispatch) => {
        dispatch({
            type: "isExplanationOn",
            payload: isOn,
        });
    };
};

export const setImagePath = (imagePath_) => {
    return (dispatch) => {
        dispatch({
            type: "imagePath",
            payload: imagePath_,
        });
    };
};

export const setIsQuestionPopupOpen = (isQuestionPopupOpen) => {
    return (dispatch) => {
        dispatch({
            type: "isQuestionPopupOpen",
            payload: isQuestionPopupOpen,
        });
    };
};

export const setQuestionsInSideBar = (questionsInSidebar) => {
    return (dispatch) => {
        dispatch({
            type: "questionsInSidebar",
            payload: questionsInSidebar,
        });
    };
};

export const setFirstQuestionId = (questionId) => {
    return (dispatch) => {
        dispatch({
            type: "firstQuestionId",
            payload: questionId,
        });
    };
};

export const setNavbarActiveTab = (activeTab) => {
    return (dispatch) => {
        dispatch({
            type: "navbarActiveTab",
            payload: activeTab,
        });
    };
};

export const setQuizTitle = (quizTitle) => {
    return (dispatch) => {
        dispatch({
            type: "quizTitle",
            payload: quizTitle,
        });
    };
};

export const setIsFromCreateQuiz = (isFromCreateQuiz) => {
    return (dispatch) => {
        dispatch({
            type: "isFromCreateQuiz",
            payload: isFromCreateQuiz,
        });
    };
};

export const loginSuccess = (userData) => {
  return {
    type: 'LOGIN_SUCCESS',
    payload: userData,
  };
};

export const setErrorNotification = (notification) => {
    return (dispatch) => {
        dispatch({
            type: "errorNotification",
            payload: notification,
        });
    };
};

export const setQuizQuestions = (questions) => {
    return (dispatch) => {
        dispatch({
            type: "quizQuestions",
            payload: questions,
        });
    };
};

export const setQuizProgress = (Progress) => {
    return (dispatch) => {
        dispatch({
            type: "quizProgress",
            payload: Progress,
        });
    };
};

export const setTakeQuizQuestion = (question) => {
    return (dispatch) => {
        dispatch({
            type: "takeQuizQuestion",
            payload: question,
        });
    };
};

export const setQuizGeneralSettings = (generalSettings) => {
    return (dispatch) => {
        dispatch({
            type: "quizGeneralSettings",
            payload: generalSettings,
        });
    };
};

export const setQuizBrowserSettings = (browserSettings) => {
    return (dispatch) => {
        dispatch({
            type: "quizBrowserSettings",
            payload: browserSettings,
        });
    };
};

export const setQuizResult = (result) => {
    return (dispatch) => {
        dispatch({
            type: "quizResult",
            payload: result,
        });
    };
};

export const setCompleteQuestion = (question) => {
    return (dispatch) => {
        dispatch({
            type: "completeQuestion",
            payload: question,
        });
    };
};

export const setIsLoading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: "isLoading",
            payload: isLoading,
        });
    };
};

export const setIsTimeOutForceSubmit = (isTimeOut) => {
    return (dispatch) => {
        dispatch({
            type: "isTimeOutForceSubmit",
            payload: isTimeOut,
        });
    };
};

export const setIsSecuritySubmit = (isSecuritySubmit) => {
    return (dispatch) => {
        dispatch({
            type: "isSecuritySubmit",
            payload: isSecuritySubmit,
        });
    };
}